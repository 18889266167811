
import { PropType, defineComponent } from "vue";
import { DetailBlogPost } from "@toppick/common/build/interfaces";
import moment from "moment";

export default defineComponent({
  props: {
    blog: {
      type: Object as PropType<DetailBlogPost>,
      required: false,
    },
  },
  data() {
    return {
      RightArrow: require("@/assets/images/right-arrow-black.svg"),
      accessTime: require("@/assets/images/blog-access_time.svg"),
    };
  },
  computed: {
    displayedTime() {
      return this.blog && this.blog.createdAt
        ? moment(this.blog.createdAt).format("L")
        : "";
    },
  },
});
