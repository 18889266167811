import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "body_wrap about_page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_section = _resolveComponent("header-section")!
  const _component_content_section = _resolveComponent("content-section")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.blog)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_header_section, {
            title: _ctx.blog.title
          }, null, 8, ["title"]),
          _createVNode(_component_content_section, { blog: _ctx.blog }, null, 8, ["blog"])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_loader, { show: _ctx.loading }, null, 8, ["show"])
  ], 64))
}