
import { defineComponent } from "vue";
import { getPostDetails } from "@toppick/common/build/api/blog";
import HeaderSection from "./HeaderSection.vue";
import Loader from "@/components/ui/Loader.vue";
import ContentSection from "./ContentSection.vue";
import { DetailBlogPost } from "@toppick/common/build/interfaces";
import "./styles.css";
export default defineComponent({
  components: { HeaderSection, Loader, ContentSection },
  data() {
    return {
      loading: true,
      blog: null as DetailBlogPost | null,
    };
  },
  async mounted() {
    try {
      const blog = await getPostDetails(this.$route.params.slug as string);
      if (blog) {
        this.blog = blog;
      }
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  },
});
